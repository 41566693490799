import { Batch } from './batch';
import { Attachment } from './attachment';

export class RawLot {

  LotId: string;
  ArrivalDate: Date;
  Notes: string;
  IsPublic: boolean;
  Batches: Array<Batch>;
  Attachments: Array<Attachment>;

  constructor(defaults: {} = null) {
    this.LotId = '';
    this.ArrivalDate = new Date();
    this.IsPublic = false;
    this.Batches = new Array<Batch>();
    this.Attachments = new Array<Attachment>();
    if ( defaults ) {
      for ( const key in defaults) {
        if( key === "Attachments" ){
          defaults[key].forEach( attachment => this.Attachments.push(new Attachment(attachment)) );
        } else if( key === "Batches" ){
          defaults[key].forEach( batch => this.Batches.push(new Batch(batch)) );
        } else if ( this.hasOwnProperty(key) ) {
          this[key] = defaults[key];
        }
      }
    }

  }

}
