import { v4 as uuidv4 } from 'uuid';


export class Attachment {
  LotId: string;
  BatchId: string;
  AttachmentId: string;
  Title: string;
  DateAdded: Date;
  IsPublic: boolean;
  LabType: string;
  Notes: string;
  Url: string;
  Brand: string;
  Incoming: string;
  OriginUrl: string;
  TotalCBD: string;
  TotalTHC: string;

  constructor( defaults: {} = null ) {
    this.AttachmentId = uuidv4();
    this.Title = '';
    this.Brand = '';
    this.DateAdded = new Date();
    this.IsPublic = false;
    this.LabType = 'External';
    this.Url = '';
    this.Notes = '';
    this.Incoming = '';
    this.OriginUrl = '';
    this.TotalCBD = '';
    this.TotalTHC = '';
    if ( defaults ) {
      for ( const key in defaults) {
        if ( this.hasOwnProperty(key) ) {
          this[key] = defaults[key];
        }
      }
    }
  }
}
