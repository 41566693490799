import { Injectable, NgZone } from '@angular/core';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { User } from '../classes/user';
import { from, Observable, ReplaySubject } from 'rxjs';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { isMatch } from 'matcher';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  private currentUser: User;
  private currentCognitoUser: any;
  private currentUserChange$ = new ReplaySubject<User>(5);
  public Authenticated = new ReplaySubject<boolean>(5);
  public CurrentUser$: Observable<User> = this.currentUserChange$.asObservable();

  constructor(
    private zone: NgZone)
  {
    this.Authenticated.next(false);
    // Listen for the authentication events
    Hub.listen('auth', (data) => {
        const { payload } = data;
        this.onAuthEvent(payload);
    });
  }

  /**
   * Determine if a user is already logged in
   */
  checkExistingLogin(){
    this.zone.run( () => {
      Auth.currentAuthenticatedUser().then( (user) => {
        this.CognitoUser = user;
      });
    });
  }

  onAuthEvent(payload) {
    console.log(payload);
    if(payload.event == 'signIn'){
      Auth.currentAuthenticatedUser().then( user => { this.CognitoUser = user; });
      Auth.currentUserCredentials().then(credentials => {
        Amplify.Credentials = credentials;
      });
    }
    if(payload.event == 'signOut'){
      this.CognitoUser = null;
    }
  }

  convertCognitoUser( cognitoUser: CognitoUserInterface ): User {
    if (cognitoUser == null) {
      return null;
    } else {
      const user = new User();
      user.email = cognitoUser.signInUserSession.idToken.payload.email;
      user.name = `${cognitoUser.signInUserSession.idToken.payload.given_name} ${cognitoUser.signInUserSession.idToken.payload.family_name}`
      user.data = cognitoUser as any;
      user.groups = user.data.signInUserSession.idToken.payload['cognito:groups'];
      return user;
    }
  }

  /**
   * Determines if the group or groups passed in match with the user groups
   * @param group group, groups, or group wildcard in space delimited format
   * @returns boolean
   * @see https://www.npmjs.com/package/matcher
   */
  public inGroup( group: string ): boolean {
    if(this.Authenticated && group !== null) {
      let userGroups = this.CurrentUser == null ? [] : this.CurrentUser.groups;
      let testGroups = Array.isArray(group) ? group : group.split(' ');
      let result = isMatch(userGroups, testGroups );
      let test=result;
      return test;
    } else {
      return false;
    }
  }

  public notInGroup( group: string ): boolean {
    return !this.inGroup(group);
  }

  set CurrentUser( newUser: User ) {
    this.currentUser = newUser;
    this.currentUserChange$.next( this.currentUser );
  }

  get CurrentUser(): User {
    return this.currentUser;
  }

  set CognitoUser( newCog: CognitoUserInterface ) {
    this.currentCognitoUser = newCog;
    this.CurrentUser = this.convertCognitoUser(this.CognitoUser);
    this.Authenticated.next( newCog != null)
  }

  get CognitoUser() {
    return this.currentCognitoUser;
  }

  getUserToken(): string {
    if(this.currentCognitoUser !== null){
      return this.currentCognitoUser.signInUserSession.idToken.jwtToken;
    }else{
      return null;
    }
  }

  signOut(): Observable<any> {
    return from(
      Auth.signOut()
      .then( () => { this.CurrentUser = null; })
    );
  }

  federatedLogin(): void {
    this.zone.run(() => Auth.federatedSignIn(
      {
        customProvider: 'AzureAD'
      }
    )
    );
  }

}
