<div id="brand-list" class="container">
  <div class="brand-component-wrapper">
    <div class="brand-list-wrapper">
      <table mat-table [dataSource]="brands">
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name
            <button mat-icon-button color="primary" aria-label="Add Brand" (click)="addBrand()">
              <mat-icon>add</mat-icon>
            </button>
      </th>
          <td mat-cell *matCellDef="let element"> {{element.brand_name}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
        mat-row
        (click)="rowClicked(row)"
        [class.row-is-clicked]="clickedRow == row"
        *matRowDef="let row; columns: displayedColumns;"
        ></tr>

      </table>
    </div>
    <div class="brand-edit-wrapper">
      <app-brand-edit [brand]="clickedRow"></app-brand-edit>

    </div>
  </div>
</div>


