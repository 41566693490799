import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';

@Directive({
  selector: '[inGroup], [notInGroup]'
})
export class InGroupDirective implements OnInit{

  @Input("inGroup")
  inGroup:string = null;

  @Input("notInGroup")
  notInGroup:string = null;


  constructor(
    private el: ElementRef,
    private userService: UserService
    ) {
   }

   ngOnInit(): void {
    this.el.nativeElement.style.display = 'none';
    this.userService.CurrentUser$.subscribe( user => {
      if( this.inGroup !== null) {
        if( this.userService.inGroup( this.inGroup ) ){
          this.el.nativeElement.style.display = 'block';
        } else {
          this.el.nativeElement.style.display = 'none';
        }
      }
      if( this.notInGroup !== null) {
        if( this.userService.notInGroup( this.notInGroup ) ){
          this.el.nativeElement.style.display = 'block';
        } else {
          this.el.nativeElement.style.display = 'none';
        }
      }
    });//subscribe
  }

}
