import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  unauthenticated: string = "hello";

  constructor(
    private userService: UserService,
    private router: Router,
    private zone: NgZone) { }


  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean|UrlTree {
      return true;
      let currentRouteUrl = route.routeConfig.path;
      if( this.userService.CurrentUser == null && (currentRouteUrl === "" || currentRouteUrl !== this.unauthenticated)  ){
          alert('You must be authenticated to view this page. You are being redirected.');
          this.zone.run( () => this.router.navigate([this.unauthenticated]));
      }
      return true;
    }
}
