import { Injectable } from '@angular/core';
import { BaseServiceService } from './base-service.service';
import { Attachment } from '../classes/attachment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService extends BaseServiceService<Attachment> {

  protected basePath = 'lot';

  getAllForLot(lotId: string): Observable<Array<Attachment>> {
    return super.getAll(`${this.basePath}/${encodeURIComponent(lotId)}/attachment`);
  }

  getAllForBatch(lotId: string, batchId: string): Observable<Array<Attachment>> {
    return super.getAll(`${this.basePath}/${encodeURIComponent(lotId)}/batch/${encodeURIComponent(batchId)}/attachment`);
  }

  getLotAttachment(id: string, lotId: string): Observable<Attachment> {
    return super.get(id, `${this.basePath}/${encodeURIComponent(lotId)}/attachment`)  ;
  }

  create(newObject: Attachment, lotId: string, batchId: string = null): Observable<Attachment> {
    let uri = `${this.basePath}/${encodeURIComponent(lotId)}/attachment`;
    if ( batchId != null) {
      uri = `${this.basePath}/${encodeURIComponent(lotId)}/batch/${encodeURIComponent(batchId)}/attachment`;
    }
    return super.create(newObject, uri);
  }

  updateAttachment(
    updateObject: Attachment,
    lotId: string = updateObject.LotId,
    batchId: string = updateObject.BatchId): Observable<Attachment> {
    let uri = `${this.basePath}/${encodeURIComponent(lotId)}/batch/${encodeURIComponent(batchId)}/attachment`;

    if ( batchId == null) {
      uri = `${this.basePath}/${encodeURIComponent(lotId)}/attachment`;
    }

    console.log(`updateAttachment ${updateObject.AttachmentId} here ${uri}`);
    return this.update(updateObject.AttachmentId.toString(), updateObject, uri);

  }

  update( id: string, updateObject: Attachment, basePath = this.basePath): Observable<Attachment> {
    if (this.userService.Authenticated) {
      console.log(`update ${id} here ${basePath}, ${JSON.stringify(updateObject)}`);
      // return this.http.put(`${this.env.rest_base}/${basePath}/${encodeURIComponent(id)}`,
      return this.http.post(`${this.env.rest_base}/${basePath}/`,
        updateObject,
        {
          headers : this.headers
        })
        .pipe(
          map( () => updateObject )
          // tap( (retVal: Attachment) =>  { console.log(retVal);this.ChangedSource.next(retVal);} )
        );
    }
  }


}
