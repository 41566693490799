import { Component, OnInit, Input } from '@angular/core';
import { Attachment } from '../classes/attachment';
import * as mime from 'mime-types';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { AttachmentModalComponent } from '../attachment-modal/attachment-modal.component';


@Component({
  selector: 'app-inline-attachment',
  templateUrl: './inline-attachment.component.html',
  styleUrls: ['./inline-attachment.component.scss']
})
export class InlineAttachmentComponent implements OnInit {

  private theattachment: Attachment;
  imagePath: string;

  constructor(
    private dialog: MatDialog
    ) { }

  ngOnInit() {
    const mimeType = mime.lookup(this.theattachment.Url);
    if( mimeType == 'application/pdf' ){
      this.imagePath = `/assets/icons/oxygen/16x16/mimetypes/application-pdf.png`;
    } else {
      this.imagePath = `/assets/icons/oxygen/16x16/mimetypes/application-rtf.png`;
    }
  }

  @Input()
  set attachment( value: Attachment) {
    this.theattachment = value;
    const mimeType = mime.lookup(value.Url);
    if( mimeType == 'application/pdf' ){
      this.imagePath = `/assets/icons/oxygen/16x16/mimetypes/application-pdf.png`;
    } else {
      this.imagePath = `/assets/icons/oxygen/16x16/mimetypes/application-rtf.png`;
    }
}

  get attachment(): Attachment {
    return this.theattachment;
  }

  editAttachment(  ) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      attachment: this.theattachment
    };

    this.dialog.open(AttachmentModalComponent, dialogConfig);
  }



}
