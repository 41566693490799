export class User {
  public name: string = '';
  public email: string = '';
  public data: any = null;
  public groups: string[] = [];
  constructor(defaults: {} = null){
    if ( defaults ) {
      for ( const key in defaults) {
        if ( this.hasOwnProperty(key) ) {
          this[key] = defaults[key];
        }
      }
    }
    this.data = defaults;
  }
}
