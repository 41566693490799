<div class="container mb-3">
    <div class="row align-items-center d-flex justify-content-between bg-light">
        <h1 class="ml-3 mb-0" mat-dialog-title>Attachment</h1>
        <mat-checkbox class="d-flex align-items-center mr-3" [(ngModel)]="Attachment.IsPublic" [disabled]="isPublicCheckboxDisabled()">Public</mat-checkbox>
    </div>
  </div>
<mat-dialog-content class="mb-4 batch-lot-form">
  <div class="attachment container">
    <div class="row">
      <div class="col-6 pl-3">
        <mat-form-field>
          <mat-label>Batch</mat-label>
          <input matInput readonly placeholder="Batch ID" #input [value]="Attachment.BatchId" type="text"/>
        </mat-form-field>
      </div>
        <div class="col-6 pl-0" *ngIf="Attachment.BatchId">
          <mat-form-field>
            <input matInput [matDatepicker]="picker" placeholder="Creation Date" [(ngModel)]="Attachment.DateAdded">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          </div>
    </div>
  </div>


  <div class="attachment-meta container">
    <div class="row">
      <div class="col-6 pl-3">
        <mat-form-field>
          <mat-label>Lab Type</mat-label>
          <mat-select [(value)]="Attachment.LabType" (selectionChange)="labTypeChanged()">
            <mat-option value="Internal">Internal</mat-option>
            <mat-option value="External">External</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6 pl-0">
        <app-brand-select required [(brand)]="Brand" ngDefaultControl></app-brand-select>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12 pl-3">
        <mat-form-field>
            <mat-label>Title</mat-label>
            <input matInput [errorStateMatcher]="this" placeholder="Attachment Title" required #input [(ngModel)]="Attachment.Title" type="text"/>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
        <div class="col-12">
          <div class="attachment-notes">
              <mat-form-field class="example-full-width">
                <textarea matInput placeholder="Attachment Notes" [(ngModel)]="Attachment.Notes"></textarea>
              </mat-form-field>
            </div>
      </div>
      <div class="col-12">
          <ngx-file-drop class="rounded" dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)"
          (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
              <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                  <div *ngIf="fileName; else noFile" class="attachment-info">
                      <img [src]="imagePath"/>&nbsp;<span class="attachment-info-name">{{fileName}}</span>
                    </div>
                    <ng-template #noFile>
                        Drop Attachment File Here...
                    </ng-template>
                    <br>
                <button class="mat-raised-button" type="button" (click)="openFileSelector()">Browse Files</button>
              </ng-template>
          </ngx-file-drop>
        </div>
    </div>
  </div>
  <mat-progress-bar mode="determinate" [value]="uploadProgress"></mat-progress-bar>
</mat-dialog-content>

<div class="container p-0">
  <div class="row">
    <div class="col-12 pl-3 pr-3">
    <mat-dialog-actions class="modal-dialog-btns py-3 px-3 d-flex justify-content-between">
        <button class="mat-raised-button btn-secondary"(click)="close()">Close</button>
        <button class="mat-raised-button submit-btn btn-success"(click)="save()" [disabled]="!formIsValid">Save</button>
    </mat-dialog-actions>
    </div>
  </div>
</div>
