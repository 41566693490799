<mat-dialog-content>
  <div class="batch" [formGroup]="form">
    <div class="row">
      <div class="col">
        <mat-form-field>
          <mat-label>Batch</mat-label>
          <input matInput [errorStateMatcher]="this" formControlName="batchNameControl" [readonly]="isBatchIdReadOnly()" (change)="onBatchNameChange($event)" id="batchId" name="batchId" required upperCase/>
          <mat-error *ngIf="batchNameControl.hasError('pattern') && !batchNameControl.hasError('required')">
            Please enter a valid Batch ID
          </mat-error>
          <mat-error *ngIf="batchNameControl.hasError('required')">
            Batch ID is <strong>required</strong>
          </mat-error>
        </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field>
        <input matInput [matDatepicker]="picker" readonly="true" placeholder="Creation Date" formControlName="batchCreationDateControl">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      </div>
  </div>

  <mat-form-field style="width:100%">
      <mat-label>Product</mat-label>
      <input matInput placeHolder="200mg 30ml Peppermint Oil" formControlName="batchProductNameControl">
  </mat-form-field>
</div>
<app-inline-attachments [disabled]="Batch.BatchId === ''" class="container d-flex justify-content-between attachment-block" [(attachments)]="Batch.Attachments" [batch]="Batch"></app-inline-attachments>

</mat-dialog-content>


<div class="container bg-light px-0">
    <div class="row lot-btn-container">
      <div class="col-12 d-flex justify-content-between">
          <mat-dialog-actions>
              <button class="mat-raised-button btn-secondary"(click)="close()">Cancel</button>

          </mat-dialog-actions>


          <mat-dialog-actions>
              <button class="mat-raised-button btn-success"(click)="save()" [disabled]="form.invalid">Save</button>
          </mat-dialog-actions>
      </div>



    </div>
  </div>

