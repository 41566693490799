import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-base-dialog',
  templateUrl: './base-dialog.component.html',
  styleUrls: ['./base-dialog.component.scss']
})
export class BaseDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<BaseDialogComponent>) {
    }

  ngOnInit() {
  }

  close( value: any = null ) {
    this.dialogRef.close( value );
  }
}
