import { Injectable } from '@angular/core';
import { BrandMeta } from '../classes/brand-meta';
import { BaseServiceService } from './base-service.service';

@Injectable({
  providedIn: 'root'
})
export class BrandService extends BaseServiceService<BrandMeta> {

  protected basePath = 'brand';

}
