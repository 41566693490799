<mat-toolbar class="bg-primary">
  <mat-toolbar-row>
    <img src="/assets/images/stasis-sm.png" height="60" class="img-responsive" />
    <mat-spinner class="busy-spinner" [hidden]="Busy != true" [diameter]="65"></mat-spinner>
    <button [hidden]="User == null" mat-icon-button [matMenuTriggerFor]="menu" class="menu menu-button" aria-label="Application Switcher">
      <mat-icon>{{currentTab?.icon}}</mat-icon>{{currentTab?.name}}
    </button>
    <mat-menu #menu="matMenu" class="menu menu-list" >
      <button *ngFor="let tab of currentTabValues" mat-menu-item [inGroup]="tab.groups" class="menu menu-item" [routerLink]="tab.routerLink">
        <mat-icon>{{tab.icon}}</mat-icon>
        <span>{{tab.name}}</span>
      </button>
    </mat-menu>
    <span class="flex-spacer"></span>
    <span class="user-group" [inGroup]="'stasis-admin'">Administrator: </span>
    <span class="username">{{User?.name}}</span>
    <div class="primary-menu-row">
      <div [hidden]="User == null">
        <button type="button" class="text-light" mat-stroked-button (click)="signOut()">
          <mat-icon>account_box</mat-icon>
          <span class="ml-1">Logout</span>
        </button>
      </div>
      <div [hidden]="User != null">
        <button type="button" class="text-light" mat-stroked-button (click)="signIn()">
          <mat-icon>login</mat-icon>
          <span class="ml-1">Login</span>
        </button>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>


