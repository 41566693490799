<div class="app-body app-container">
  <div class="app-header">
    <app-header
      [Authenticated]="Authenticated" [User]="CurrentUser" [Busy]="Busy"
      (onSignIn)="signIn()" (onSignOut)="signOut()"></app-header>
  </div>
  <div class="app-main app-content">
    <app-hello [hidden]="Authenticated"></app-hello>

    <div class="App" [hidden]="!Authenticated">
      <router-outlet> </router-outlet>
    </div>

  </div>
  <div class="app-footer">
    <app-footer></app-footer>
  </div>
</div>

