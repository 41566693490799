<div class="batch-grid-editor full-width full-height">
    <ag-grid-angular #agGrid class="ag-theme-balham lab-batch-grid-editor-grid full-height" sidebar="columns"
      (cellDoubleClicked)="onCellDoubleClicked($event)" rowSelection="single" (rowSelected)="onRowSelected($event)"
      [gridOptions]="gridOptions">
    </ag-grid-angular>
    <eco-fab-speed-dial class="fab" animationMode="scale">
      <eco-fab-speed-dial-trigger [spin]="true">
        <button mat-fab (click)="addBatch()"><mat-icon>add</mat-icon></button>
      </eco-fab-speed-dial-trigger>
      <!--eco-fab-speed-dial-actions>
        <button mat-mini-fab (click)="addBatch()"><mat-icon>add</mat-icon></button>
        <button mat-mini-fab (click)="editBatch()"><mat-icon>edit</mat-icon></button>
        <button mat-mini-fab (click)="deleteBatch()"><mat-icon>delete</mat-icon></button>
      </eco-fab-speed-dial-actions-->
    </eco-fab-speed-dial>
</div>
