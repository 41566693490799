import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { BatchService } from '../services/batch.service';
import { Batch } from '../classes/batch';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorStateMatcher } from '@angular/material/core';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { Attachment } from '../classes/attachment';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-batch',
  templateUrl: './batch.component.html',
  styleUrls: ['./batch.component.scss']
})
export class BatchComponent extends BaseDialogComponent implements OnInit, ErrorStateMatcher {

  Batch: Batch;
  BatchChange = new EventEmitter<Batch>();

  batchIdPattern = environment.permittedBatchNamePattern;
  batchNameControl: FormControl;
  batchProductNameControl: FormControl;
  batchCreationDateControl: FormControl;
  matcher = this;
  form: FormGroup;
  formIsValid: boolean;
  AttachmentsChange = new EventEmitter<Attachment>();


  constructor(
    private batchService: BatchService,
    public dialogRef: MatDialogRef<BatchComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      super(dialogRef);
      this.Batch = data.batch;
      //this.attachmentService.Added$.subscribe( attachment => this.newAttachmentDetected(attachment) )
    }

  setupForm() {
    this.batchNameControl = new FormControl(this.Batch.BatchId,[Validators.required, Validators.pattern(this.batchIdPattern)]);
    this.batchProductNameControl = new FormControl(this.Batch.Product);
    this.batchCreationDateControl = new FormControl(this.Batch.CreationDate);
    this.form = new FormGroup({
      batchNameControl: this.batchNameControl,
      batchProductNameControl:this.batchProductNameControl,
      batchCreationDateControl : this.batchCreationDateControl
    });
  }

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    this.formIsValid = !(control && control.invalid && (control.dirty || control.touched || isSubmitted)) ;
    return !this.formIsValid;
  }

  isBatchIdReadOnly() {
    const hasAttachments = this.Batch.Attachments.length > 0;
    const isExistingBatch = this.Batch.BatchId !== '';
    return hasAttachments || isExistingBatch;
  }

  onBatchNameChange($event) {
    this.Batch.BatchId = this.form.get('batchNameControl').value;
  }

  formToModel() {
    this.Batch.BatchId = this.form.get('batchNameControl').value;
    this.Batch.Product = this.form.get('batchProductNameControl').value;
    this.Batch.CreationDate = new Date(this.form.get('batchCreationDateControl').value);
  }

  save() {
    if(!this.form.invalid){
      this.formToModel();
      this.BatchChange.emit(this.Batch);
      this.batchService.create( this.Batch )
      .subscribe( (batch) => super.close( batch ));
    }
  }

  close() {
    super.close(null);
  }

  newAttachmentDetected(attachment: Attachment) {
    if( ( (attachment.BatchId == "") || (attachment.BatchId == null) ) && (attachment.LotId === this.Batch.LotId) ) {
      // This is an attachment for this Lot
      if( this.Batch.Attachments.find( a => a.AttachmentId == attachment.AttachmentId ) == null ){
        this.Batch.Attachments.push(attachment);
        this.AttachmentsChange.emit(attachment);
      }
    }
  }

  ngOnInit() {
    if ( this.Batch == null ) {
      this.Batch = new Batch();
    }
    this.setupForm();
  }

}
