import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthConfig, environment } from 'src/environments/environment';
import { Observable, Subject, of } from 'rxjs';
import { UserService } from './user.service';
import { tap, map, catchError, mergeMap } from 'rxjs/operators';
import { User } from '../classes/user';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { Cacheable } from 'ts-cacheable';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseServiceService<T> {

  private EMPTY_RESPONSE: Array<T> = new Array<T>();
  protected headers: HttpHeaders;
  protected basePath = '';
  protected authenticated: boolean = false;
  protected env = environment;

  protected AddedSource: Subject<T> = new Subject<T>();
  public Added$ = this.AddedSource.asObservable();

  protected ChangedSource: Subject<T> = new Subject<T>();
  public Changed$ = this.ChangedSource.asObservable();

  protected DeletedSource: Subject<T> = new Subject<T>();
  public Deleted$ = this.DeletedSource.asObservable();

  protected HeadersSet: Subject<boolean> = new Subject<boolean>();
  protected OnHeadersSet = this.HeadersSet.asObservable();

  constructor(protected http: HttpClient, protected userService: UserService) {
    this.headers = new  HttpHeaders()
      .set('Content-Type', 'application/json');

    this.userService.CurrentUser$.subscribe( user => {
      this.authenticated = (user !== null);
      this.setHeadersForUser(user);
    } );

    this.Added$.subscribe( this.onAdded );
    this.Changed$.subscribe( this.onChanged );
    this.Deleted$.subscribe( this.onDeleted );
  }

  setHeadersForUser(user: User){
    const token = this.userService.getUserToken() || '';
    this.headers = new  HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('Authorization', `${token}`);
    this.HeadersSet.next(true);
    // this.callGraphQL();
  }

  @Cacheable()
  getAll(basePath = this.basePath): Observable<Array<T>> {
    if( this.authenticated ) {
      return this._getAll(basePath)
    } else {
      return this.OnHeadersSet.pipe(
        mergeMap( _ =>  this._getAll(basePath) )
      );
    }
  }

  private _getAll(basePath = this.basePath): Observable<Array<T>> {
    return this.http.get<Array<T>>( `${this.env.rest_base}/${basePath}`, { headers : this.headers })
    .pipe(
      catchError((err) => {
        if(err.status == 404){
          map( () => []);
          return of<Array<T>>(this.EMPTY_RESPONSE);
        }else{
          throw err;
        }
      })
    );
  }

  get(id: string, basePath = this.basePath): Observable<T> {
    if(this.authenticated){
      return this._get(id, basePath);
    }else{
      return this.OnHeadersSet.pipe(
        mergeMap( _ =>  this._get(id, basePath) )
      );
    }
  }

  private _get(id: string, basePath = this.basePath): Observable<T> {
      return this.http.get<T>( `${this.env.rest_base}/${basePath}/${encodeURIComponent(id)}`, { headers : this.headers })
    .pipe(
      catchError((err) => {
        if(err.status == 404){
          return of<T>();
        }else{
          throw err;
        }
      })
    );
  }

  create( newObject: T, basePath = this.basePath): Observable<T> {
    if(this.authenticated){
      return this._create(newObject, basePath);
    }else{
      return this.OnHeadersSet.pipe(
        mergeMap( _ =>  this._create(newObject, basePath) )
      );
    }
  }

  private _create( newObject: T, basePath = this.basePath): Observable<T> {
    return this.http.post<T>(`${this.env.rest_base}/${basePath}`, newObject, {
      headers : this.headers
    }).pipe(
      map( () => newObject),
      tap( ret => this.AddedSource.next(ret) )
    );
  }

  update( id: string, updateObject: T, basePath = this.basePath): Observable<T> {
    if(this.authenticated){
      return this._update(id, updateObject, basePath);
    }else{
      return this.OnHeadersSet.pipe(
        mergeMap( _ =>  this._update(id, updateObject, basePath) )
      );
    }
  }

  private _update( id: string, updateObject: T, basePath = this.basePath): Observable<T> {
    return this.http.post<T>(`${this.env.rest_base}/${basePath}/${encodeURIComponent(id)}`,
    updateObject,
    {
      headers : this.headers
    }).pipe(
      map( () => updateObject),
      tap( ret => this.ChangedSource.next(ret) )
    );
  }

  delete( id: string, updateObject: T = null, basePath = this.basePath): Observable<T> {
    if(this.authenticated){
      return this._delete(id, updateObject, basePath);
    }else{
      return this.OnHeadersSet.pipe(
        mergeMap( _ =>  this._delete(id, updateObject, basePath) )
      );
    }
  }

  private _delete( id: string, updateObject: T = null, basePath = this.basePath): Observable<T> {
    return this.http.delete(`${this.env.rest_base}/${basePath}/${encodeURIComponent(id)}`,
    {
      headers : this.headers
    }).pipe(
      map( () => updateObject),
      tap( ret => this.DeletedSource.next(ret) )
    );
  }

  protected onDeleted( obj: T) {
    console.log(`Deleted ${obj.constructor.name} Event: ${JSON.stringify(obj)}`);
  }
  protected onAdded( obj: T) {
    console.log(`Added ${obj.constructor.name} Event: ${JSON.stringify(obj)}`);
  }
  protected onChanged( obj: T) {
    console.log(`Changed ${obj.constructor.name} Event: ${JSON.stringify(obj)}`);
 }

 callGraphQL() {
  console.log(`Calling GraphQL`);
  API.configure(AuthConfig.Auth);
  // TODO: This is the GraphQL Impl test
  let gql = (API.graphql(graphqlOperation(`
  query GetLots($LotId: String!) {
    getLots(LotId: $LotId){
      LotId
      Batches {
        BatchId
        CreationDate
        Brand
        IsPublic
        Attachments {
          AttachmentId
          DateAdded
          Brand
          LabType
          Url
          Title
          IsPublic
        }
      }
    }
  }`, {LotId: 'UNKNOWN'} )) as Promise<GraphQLResult>);
  gql
  //.then( console.log )
  .then( data  =>
    console.log(JSON.stringify(data))
   );
  console.log(`Called GraphQL`);
}


}
