import { Component, Input, OnInit } from '@angular/core';
import { BrandMeta } from '../classes/brand-meta';

@Component({
  selector: 'app-brand-edit',
  templateUrl: './brand-edit.component.html',
  styleUrls: ['./brand-edit.component.scss']
})
export class BrandEditComponent implements OnInit {

  @Input()
  brand: BrandMeta;


  constructor() { }

  ngOnInit(): void {
  }

}
