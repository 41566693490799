// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const awsmobile = require( "../aws-exports").awsmobile;

const BASE_SSO_DOMAIN = 'sso.globalwidget.com',
      S3_KEY_PREFIX = 'public',
      BASE_SERVICE_URL = `https://${BASE_SSO_DOMAIN}`,
      REDIRECT_URL = 'https://stasis.globalwidget.com',
      REST_BASE = 'https://hsen7xfk43.execute-api.us-east-2.amazonaws.com',
      REST_STAGE = 'prod',
      CLIENT_ID = '7ni8u8uq2eulrd3tbb4d95olq8',
      GTM_ID = 'GTM-N9BL6B2';


export const environment = {
  permittedIsolateNamePattern: '^[A-Z0-9]*[A-Z0-9-_]*[A-Z0-9]*$',
  permittedBatchNamePattern: '^[A-Z0-9]*[A-Z0-9-_]*[A-Z0-9]*$',
  domain : BASE_SSO_DOMAIN,
  production: true,
  rest_base: `${REST_BASE}/${REST_STAGE}`,
  s3_prefix: S3_KEY_PREFIX,
  s3_base: `https://qwerty-artifacts-dev.s3.us-east-2.amazonaws.com/${S3_KEY_PREFIX}`,
  icon_lot: 'gamepad',
  icon_batch: 'opacity',
  icon_attachment: 'attach_file',
  traceRoutes: false,
  redirectURL: `${REDIRECT_URL}/`,
  cognitoUserInfoURL: `${BASE_SERVICE_URL}/oauth2/userInfo`,
  cognitoBaseURL: `${BASE_SERVICE_URL}`,
  auth_url: `${BASE_SERVICE_URL}/login?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URL}/`,
  gtmID: GTM_ID
};

export const AuthConfig = {
  Auth : {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: 'us-east-1:fa4daa2e-999d-4dac-b1d5-dd2fec414f1e',

    // REQUIRED - Amazon Cognito Region
    region: 'us-east-1',

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region`
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: 'us-east-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'us-east-1_KdNmup4y6',

    // REQUIRED
    RoleArn: '',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: CLIENT_ID,
    userPoolWebClientSecret: '1fhu244nt55mn3n2vqdtub4uthcjfnuvpo8r66tlkpu3mmh2ej08',
    oauth : {
      // Domain name
      domain : BASE_SSO_DOMAIN,
      // Authorized scopes
      scope: ['email', 'profile', 'openid'],
      // Callback URL
      redirectSignIn : `${REDIRECT_URL}/`,
      // Sign out URL
      redirectSignOut : `${REDIRECT_URL}/signOut`,
      // 'code' for Authorization code grant,
      // 'token' for Implicit grant
      responseType: 'code',
      // optional, for Cognito hosted ui specified options
      options: {
        // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        AdvancedSecurityDataCollectionFlag : true
      },
    },
    aws_appsync_graphqlEndpoint: 'https://fl5l6v3oife53pgwxjpddprzza.appsync-api.us-east-1.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS', // You have configured Auth with Amazon Cognito User Pool ID and Web Client Id
    //aws_appsync_authenticationType: 'API_KEY',
    //aws_appsync_apiKey: 'da2-p7ftpxaqwnddjelmo4eybskqoe',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true},

  Storage: {
    AWSS3: {
      bucket: 'qwerty-artifacts-dev',
      region: 'us-east-2'
    }
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.



