import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private keyboardNavSource = new Subject<KeyboardEvent>();
  newKeyboardNavMessage$ = this.keyboardNavSource.asObservable();

  private busyComponentCount: number = 0;
  private isBusySource = new BehaviorSubject<boolean>(false);
  Busy = this.isBusySource.asObservable();

  constructor(private zone: NgZone) { }

  newKeyboardNavEvent(event$: KeyboardEvent) {
    this.keyboardNavSource.next(event$);
  }

  isBusy(busy:boolean) {
    if(busy){
      this.busyComponentCount++;
    } else {
      this.busyComponentCount--;
    }
    this.zone.run( () => {
      this.isBusySource.next(this.busyComponentCount <= 0);
    });
  }


}
