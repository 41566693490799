import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { Amplify } from 'aws-amplify';
import { AttachmentService } from '../services/attachment.service';
import { Attachment } from '../classes/attachment';
import { environment } from 'src/environments/environment';
import * as mime from 'mime-types';
import { BrandService } from '../services/brand.service';
import { BrandMeta } from '../classes/brand-meta';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'app-attachment-modal',
  templateUrl: './attachment-modal.component.html',
  styleUrls: ['./attachment-modal.component.scss']
})
export class AttachmentModalComponent extends BaseDialogComponent implements OnInit, ErrorStateMatcher {
  public files: NgxFileDropEntry[] = [];
  Attachment: Attachment;
  env = environment;
  brands: BrandMeta[];
  fileName: string;
  file: File;
  uploadProgress = 0;
  customTitleSet = false;
  imagePath: string;
  hasFileChanged = false;
  formIsValid: boolean;

  constructor(
    private brandService: BrandService,
    private attachmentService: AttachmentService,
    public dialogRef: MatDialogRef<AttachmentModalComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      super(dialogRef);
      this.brandService.getAll().subscribe(brands => this.brands = brands);
      this.Attachment = data.attachment || new Attachment();
      this.Attachment.LotId = data.lotId || this.Attachment.LotId ||  null;
      this.Attachment.BatchId = data.batchId || this.Attachment.BatchId || null;
      if ( data.attachment ) {
        this.lookupMimeType( this.Attachment.Url );
        const leafname = this.Attachment.Url.split('\\').pop().split('/').pop();
        this.fileName = leafname;
        this.customTitleSet = true;
      }
  }

  ngOnInit() {
    this.labTypeChanged();
    this.setInforForFile( this.Attachment.Url );
  }

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    this.formIsValid = !(control && control.invalid && (control.dirty || control.touched || isSubmitted)) ;
    this.formIsValid &&= this.Attachment.Brand !== '';
    return !this.formIsValid;
  }


  lookupMimeType( fileName: string ) {
    return mime.lookup( fileName );
  }

  setInforForFile( pathOrUrl: string ) {
    if ( pathOrUrl ) {
      const mimeType = this.lookupMimeType(pathOrUrl);
      this.imagePath = `/assets/icons/oxygen/22x22/mimetypes/${mimeType.replace('/', '-')}.png`;
    }
  }

  fileChanged( file: File ) {
    this.hasFileChanged = true;
    this.file = file;
    this.fileName = file.name;
    this.setInforForFile( file.name );
  }

  labTypeChanged() {
    if ( !this.customTitleSet ) {
      if ( this.Attachment.LabType === 'Internal') {
        this.Attachment.Title = 'Internal Lab Test';
        this.Attachment.Notes = 'Test report from our Internal Lab';
      } else {
        this.Attachment.Title = 'External Lab Test';
        this.Attachment.Notes = 'Test report from an External Lab';
      }
    }
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.fileChanged ( file );
          // Here you can access the real file
          console.log(droppedFile.relativePath, file);
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }
  get Brand(): string {
    return this.Attachment.Brand;
  }

  set Brand( value: string ) {
    this.Attachment.Brand = value;
  }

  uploadProgressCallback( progress ) {
    // const status = Math.round( progress.loaded / progress.total * 100 );
    // this.uploadProgress = status;
    // console.log('Uploaded : ', progress);
  }

  XOR(a, b){
    return (( a && !b) || (!a && b));
  }

  isPublicCheckboxDisabled(): boolean {

    const urlSet = this.Attachment.Url !== '';

    const fileNameExists = this.file && this.file.name;

    const hasAttachment = this.XOR(urlSet, fileNameExists);

    const brandIsSet = this.Attachment.Brand !== '';

    return !(hasAttachment && brandIsSet);
  }

  save() {
    if ( this.hasFileChanged ) {
      const s3FileKey = `${this.file.name}`;
      //this.storage.put
      Amplify.Storage.put
      (s3FileKey, this.file, {
            level: 'public',
            contentType: this.file.type
          }).then((result: any) => {
                  console.log('Success =>', result);
                  this.Attachment.Url = `${this.env.s3_base}/${this.file.name}`;
                  this.attachmentService.create(
                    this.Attachment,
                    this.Attachment.LotId,
                    this.Attachment.BatchId
                  ).subscribe( (attachment) => { this.close(attachment)});
      })
      .catch(console.error);

    } else {
      this.attachmentService.create(
        this.Attachment,
        this.Attachment.LotId,
        this.Attachment.BatchId
      ).
      subscribe( (attachment) => {
        this.close(attachment);
      });

    }
  }

  public fileOver(event) {
    console.log(event);
  }

  public fileLeave(event) {
    console.log(event);
  }

}
