import { Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Attachment } from 'src/API';

@Component({
  selector: 'app-batch-grid-cell-attachment',
  templateUrl: './batch-grid-cell-attachment.component.html',
  styleUrls: ['./batch-grid-cell-attachment.component.scss']
})
export class BatchGridCellAttachmentComponent implements ICellRendererAngularComp  {

  @Input()
  Attachments: Array<Attachment>;
  private $dynoshow: any;
  private $dynoGallery: any;

  constructor() { }


  agInit(params: ICellRendererParams): void {
    this.Attachments = params.value;
  }

  refresh( params: ICellRendererParams) {
    return false;
  }

  onClick(attachment: Attachment, index:number) {
  }

  isPublic(attachment: Attachment){
    if( attachment.IsPublic === 'true' ){
      return 'is-public';
    } else {
      return 'is-not-public';
    }
  }

}
