<h1>Brand</h1>
<div *ngIf="brand">
  <div class="batch">
    <div class="row">
      <div class="column">
        <mat-form-field>
            <mat-label>Brand Name</mat-label>
            <input matInput required #input [value]="brand.brand_name" type="text"/>
        </mat-form-field>
    </div>
    <div class="column">
      <mat-form-field>
          <mat-label>Brand Index</mat-label>
          <input matInput required #input [value]="brand.brand_number" type="number"/>
      </mat-form-field>
    </div>
  </div>
    <div class="row">
      <div class="column">
        <mat-form-field>
            <mat-label>Brand URL</mat-label>
            <input matInput placeholder="https://brand.com" required #input [(ngModel)]="brand.store_url" type="text"/>
        </mat-form-field>
      </div>
      <div class="column">
        <mat-form-field>
            <mat-label>Brand Slug</mat-label>
            <input matInput placeholder="https://brand.com" required #input [(ngModel)]="brand.slug" type="text"/>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
