import { Injectable } from '@angular/core';
import { RawLot } from '../classes/raw-lot';
import { BaseServiceService } from './base-service.service';
import { BatchService } from './batch.service';
import { AttachmentService } from './attachment.service';
import { HttpClient } from '@angular/common/http';
import { UserService } from './user.service';
import { Observable, forkJoin } from 'rxjs';
import { Batch } from '../classes/batch';
import { mergeMap, map, tap } from 'rxjs/operators';
import { Attachment } from '../classes/attachment';
/**
 * Injectable
 */
@Injectable({
  providedIn: 'root'
})

export class RawLotService extends BaseServiceService<RawLot> {
  protected basePath = 'lot';

  constructor(
    protected http: HttpClient,
    protected userService: UserService,
    protected batchService: BatchService,
    protected attachmentService: AttachmentService ) {
      super( http, userService );
  }

  promoteAttachmentsToLot( lotId: string, batchId: string ): Observable<any> {
    return this.attachmentService.getAllForBatch( lotId, batchId ) /*
    .pipe(
      map( attachments => {
        console.log(`Attachments returned ${attachments.keys}`);
        forkJoin( [...attachments.map( attachment => {
            attachment.LotId = attachment.BatchId;
            attachment.BatchId = null;
            this.attachmentService.updateAttachment( attachment, lotId );
          }) // .map
        ]); // forkJoin
    }) // map()
    ); // pipe
    */
   .pipe(
    mergeMap( (attachments: Attachment[]) => {
      console.log(`Attachments returned ${attachments.keys}`);
      return forkJoin( [...attachments.map( attachment => {
          attachment.LotId = attachment.BatchId;
          attachment.BatchId = null;
          this.attachmentService.updateAttachment( attachment, lotId, batchId )
          .pipe(
            tap( att => {
              console.log(`${att.AttachmentId} is updated. ${JSON.stringify(att)}`);
            })
          );
        }) // .map
      ]); // forkJoin
  }, 1) // map()
  ); // pipe
}

  promoteToLot( batch: Batch ): Observable<RawLot> {
    console.log(`RawLot.Service.promoteToLot(${batch.BatchId})`);
    // create new Lot from Batch
    const batchId = batch.BatchId;
    const lotId = batch.LotId;
    const newLot = new RawLot();
    newLot.LotId = batch.BatchId;
    newLot.ArrivalDate = batch.CreationDate;
    newLot.IsPublic = batch.IsPublic;
    newLot.Notes = batch.Notes;

    return forkJoin([
        this.create( newLot ),
       // this.promoteAttachmentsToLot( lotId, batchId ),
        this.batchService.delete( batch.BatchId, batch )
      ])
      .pipe(
        map( () => newLot )
      );

  }


}
