import { Injectable } from '@angular/core';
import { BaseServiceService } from './base-service.service';
import { Observable, forkJoin } from 'rxjs';
import { Batch } from '../classes/batch';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UserService } from './user.service';
import { AttachmentService } from './attachment.service';
import { Attachment } from '../classes/attachment';

@Injectable({
  providedIn: 'root'
})
export class BatchService extends BaseServiceService<Batch> {
  protected basePath = 'lot';

  constructor(
    protected http: HttpClient,
    protected userService: UserService,
    protected attachmentService: AttachmentService) {
    super( http, userService);
  }

  getAll(lotId: string): Observable<Array<Batch>> {
    return super.getAll(`${this.basePath}/${encodeURIComponent(lotId)}/batch`);
  }

  get(id: string, lotId: string): Observable<Batch> {
    return super.get(id, `${this.basePath}/${encodeURIComponent(lotId)}/batch/${encodeURIComponent(id)}`);
  }

  getWithAttachments( id: string, lotId: string): Observable<Batch> {
    return forkJoin([
      this.get( id, lotId),
      this.attachmentService.getAllForBatch( lotId, id )
    ]).subscribe( result => {
      const batch = result[0];
      batch.Attachments = result[1];
    })[0];
  }

  create( newObject: Batch): Observable<Batch> {
    return super.create(newObject, `${this.basePath}/${encodeURIComponent(newObject.LotId)}/batch`);

  }

  private updateAttachments(updateObject: Batch): Observable<Attachment[]> {
    return forkJoin( [...updateObject.Attachments.map( attachment => {
      const oldLotId = attachment.LotId;
      const oldBatchId = attachment.BatchId;
      attachment.LotId = updateObject.LotId;
      attachment.BatchId = updateObject.BatchId;
      return this.attachmentService.updateAttachment( attachment, oldLotId, oldBatchId );
    })]);
  }

  update( id: string, updateObject: Batch): Observable<Batch> {
    return forkJoin(
      super.update('', updateObject, `${this.basePath}/${encodeURIComponent(updateObject.LotId)}/batch`),
      this.updateAttachments( updateObject )
    )
    .pipe( map( resultsArray => {
      const batchObject: Batch = resultsArray[0];
      const attachmentsArray: Array<Attachment> = resultsArray[1];
      batchObject.Attachments = attachmentsArray;
      return batchObject;
    }));
  }

  delete( id: string, updateObject: Batch = null ): Observable<Batch> {
    return super.delete(updateObject.BatchId, updateObject, `${this.basePath}/${encodeURIComponent(id)}/batch`);
  }
}
