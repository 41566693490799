import { Injectable } from '@angular/core';
import { RawLot } from '../classes/raw-lot';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { API, graphqlOperation } from 'aws-amplify';
import { onCreateAttachment } from '../../graphql/subscriptions';
import { AuthConfig } from 'src/environments/environment';
import { from, Observable, of, Subject } from 'rxjs';
import { UserService } from './user.service';
import { map } from 'rxjs/operators';
import { Batch } from '../classes/batch';
import { Cacheable } from 'ts-cacheable';
import { Attachment } from '../classes/attachment';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import * as mutations from 'src/graphql/mutations';
import * as subscriptions from 'src/graphql/subscriptions';

@Injectable({
  providedIn: 'root'
})
export class BaseGraphqlServiceService {

  AttachmentCreated  = new Subject<Attachment>();
  AttachmentModified = new Subject<Attachment>();
  AttachmentDeleted  = new Subject<Attachment>();
  BatchCreated       = new Subject<Batch>();
  BatchModified      = new Subject<Batch>();
  BatchDeleted       = new Subject<Batch>();

  private _batches: Observable<Array<Batch>> = null;


  constructor(
    private userService: UserService
  ) {
    API.configure(AuthConfig.Auth);
    //this.setupBatchSubscribers();
  }

  private activator<T>(type: { new(): T ;} ): T {
    return new type();
  }

  public query( query: any, variables?: {}): Observable<object> {
    return from(API.graphql(graphqlOperation(query, variables)) as Promise<GraphQLResult<object>>)
    .pipe(map( results => Object.values(results.data)[0] ))
  }

  private setupBatchSubscribers() {
    // TODO: Set up subscription @see https://docs.amplify.aws/lib/graphqlapi/subscribe-data/q/platform/js/
    const service = API.graphql(graphqlOperation(subscriptions.onCreateBatch));
    (service as unknown as Observable<object>)
    .subscribe({
      next: (data) => { console.log(JSON.stringify(data,null,2));},//this.AttachmentCreated.next(data),
      error: error => console.warn(error)
    })
  }

  private setupAttachmentSubscribers() {

    // TODO: Set up subscription @see https://docs.amplify.aws/lib/graphqlapi/subscribe-data/q/platform/js/
  }

  get Batches(): Observable<Array<Batch>> {
    if(this._batches == null){
      this._batches = this.getBatches();
    }
    return this._batches;
  }


  getUnknownLots(): Observable<RawLot> {
    return this.query(`
    query GetLots($LotId: String!) {
      getLots(LotId: $LotId){
        LotId
        Batches {
          BatchId
          CreationDate
          Brand
          IsPublic
          Attachments {
            AttachmentId
            DateAdded
            Brand
            LabType
            Url
            Title
            IsPublic
          }
        }
      }
    }`, {LotId: 'UNKNOWN'} )
    .pipe(map( results  => new RawLot(results) ));
  }

  createBatch(newBatch: Batch): Observable<object> {
    //TODO: call mutator
    return this.query(`  mutation CreateBatch($input: CreateBatchInput!) {
      createBatch(input: $input) {
        Attachments {
          AttachmentId
          BatchId
          Brand
          DateAdded
          Incoming
          IsPublic
          LabType
          LotId
          Notes
          OriginUrl
          Title
          TotalCBD
          TotalTHC
          Url
        }
        BatchId
        Brand
        CreationDate
        IsPublic
        LotId
        Product
      }
    }
  `, {input: newBatch})
    .pipe(map( results => new Batch(results)));
  }

  @Cacheable()
  getBatches(): Observable<Array<Batch>> {
    return this.query(`query ListBatches {
      listBatches(filter: {BatchId: {ne: " "}}, limit: 5000) {
        items {
          Attachments {
            AttachmentId
            BatchId
            Brand
            DateAdded
            Incoming
            IsPublic
            LabType
            LotId
            Notes
            OriginUrl
            Title
            TotalCBD
            Url
            TotalTHC
          }
          BatchId
          Brand
          CreationDate
          IsPublic
          Product
          LotId
        }
      }
    }`)
    .pipe(map( results  => {
      let batchArr = results['items'];
      let batches = new Array<Batch>();
      batchArr.forEach(batch => batches.push(new Batch(batch)));
      return batches.sort( (a,b) => a.CreationDate < b.CreationDate ? 1 : -1);
    }));
  }

  getBatch( batchId:string): Observable<Batch> {
    return this.query(`query GetBatch($BatchId: String) {
      getBatch(BatchId: $BatchId) {
        BatchId
        Brand
        CreationDate
        IsPublic
        LotId
        Product
        Lot {
          ArrivalDate
          IsPublic
          LotId
        }
        Attachments {
          AttachmentId
          BatchId
          Brand
          DateAdded
          Incoming
          IsPublic
          LabType
          LotId
          Notes
          OriginUrl
          Title
          TotalCBD
          TotalTHC
          Url
        }
      }
    }
    `,{BatchId: batchId})
    .pipe(map( results  => {return new Batch(results)}));
  }


  getAllLots(): Observable<Array<RawLot>> {
    return this.query(`query MyQuery {
      listLots(filter: {LotId: {ne: " "}}, limit: 500) {
        items {
          LotId
          ArrivalDate
          IsPublic
          Batches {
            BatchId
            IsPublic
            CreationDate
            Brand
            Attachments {
              AttachmentId
              DateAdded
              Brand
              LabType
              Url
              Title
              IsPublic
            }
          }
        }
      }
    }`)
    .pipe(map( results  => {
      let lotArr = results['items'];
      let lots = new Array<RawLot>();
      lotArr.forEach(lot => lots.push(new RawLot(lot)));
      return lots;
    }));
  }

  callGraphQL() {
    console.log(`Calling GraphQL`);
    API.configure(AuthConfig.Auth);
    // TODO: This is the GraphQL Impl test
    let gql = (API.graphql(graphqlOperation(`
    query GetLots($LotId: String!) {
      getLots(LotId: $LotId){
        LotId
        Batches {
          BatchId
          CreationDate
          Brand
          IsPublic
          Attachments {
            AttachmentId
            DateAdded
            Brand
            LabType
            Url
            Title
            IsPublic
          }
        }
      }
    }`, {LotId: 'UNKNOWN'} )) as Promise<GraphQLResult>);
    gql
    .then( results  => {
       let LotObj = results.data['getLots'];
       console.log(LotObj);
       let Lot = new RawLot(LotObj);
       console.log(Lot);
    }
     );
    console.log(`Called GraphQL`);
  }

}
