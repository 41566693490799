
<div class="container">
  <div class="row d-flex justify-content-between bg-light">
      <h1 class="mb-0" mat-dialog-title>Raw Isolate</h1>
      <mat-checkbox [(ngModel)]="Lot.IsPublic">Public</mat-checkbox>
  </div>
</div>

<mat-dialog-content class="mb-4">
    <div class="raw-lot">
      <div class="row">
        <div class="col" [formGroup]="form">
          <mat-form-field>
            <mat-label>Raw Isolate Name</mat-label>
            <input matInput [errorStateMatcher]="this" [(ngModel)]="Lot.LotId" formControlName="lotNameControl" id="lotId" name="lotId" required upperCase/>
            <mat-error *ngIf="lotNameControl.hasError('pattern') && !lotNameControl.hasError('required')">
              Please enter a valid Isolate ID
            </mat-error>
            <mat-error *ngIf="lotNameControl.hasError('required')">
              Isolate ID is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field>
                <input matInput [matDatepicker]="picker" placeholder="Arrival Date" [(ngModel)]="Lot.ArrivalDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
        </div>
      </div>




      <app-inline-attachments class="container d-flex justify-content-between attachment-block" [attachments]="Attachments"  [lot]="Lot"></app-inline-attachments>
    </div>
</mat-dialog-content>

<div class="container bg-light px-0">
  <div class="row lot-btn-container">
    <div class="col-12 d-flex justify-content-between">
        <mat-dialog-actions>
            <button class="mat-raised-button btn-secondary"(click)="close()">Cancel</button>

        </mat-dialog-actions>


        <mat-dialog-actions>
            <button [disabled]="form.invalid" class="mat-raised-button btn-success"(click)="save()">Save</button>
        </mat-dialog-actions>
    </div>



  </div>
</div>

