/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateAttachment = /* GraphQL */ `
  subscription OnCreateAttachment(
    $AttachmentId: String
    $BatchId: String
    $LotId: String
  ) {
    onCreateAttachment(
      AttachmentId: $AttachmentId
      BatchId: $BatchId
      LotId: $LotId
    ) {
      AttachmentId
      Batch {
        BatchId
        Brand
        CreationDate
        IsPublic
        LotId
        Product
      }
      BatchId
      Brand
      DateAdded
      Incoming
      IsPublic
      LabType
      Lot {
        ArrivalDate
        IsPublic
        LotId
      }
      LotId
      Notes
      OriginUrl
      Title
      TotalCBD
      TotalTHC
      Url
    }
  }
`;
export const onCreateBatch = /* GraphQL */ `
  subscription OnCreateBatch($BatchId: String, $Brand: String, $LotId: String) {
    onCreateBatch(BatchId: $BatchId, Brand: $Brand, LotId: $LotId) {
      Attachments {
        AttachmentId
        BatchId
        Brand
        DateAdded
        Incoming
        IsPublic
        LabType
        LotId
        Notes
        OriginUrl
        Title
        TotalCBD
        TotalTHC
        Url
      }
      BatchId
      Brand
      CreationDate
      IsPublic
      Lot {
        ArrivalDate
        IsPublic
        LotId
      }
      LotId
      Product
    }
  }
`;
export const onCreateLots = /* GraphQL */ `
  subscription OnCreateLots(
    $ArrivalDate: String
    $IsPublic: String
    $LotId: String
  ) {
    onCreateLots(
      ArrivalDate: $ArrivalDate
      IsPublic: $IsPublic
      LotId: $LotId
    ) {
      ArrivalDate
      Attachments {
        AttachmentId
        BatchId
        Brand
        DateAdded
        Incoming
        IsPublic
        LabType
        LotId
        Notes
        OriginUrl
        Title
        TotalCBD
        TotalTHC
        Url
      }
      Batches {
        BatchId
        Brand
        CreationDate
        IsPublic
        LotId
        Product
      }
      IsPublic
      LotId
    }
  }
`;
export const onDeleteAttachment = /* GraphQL */ `
  subscription OnDeleteAttachment(
    $AttachmentId: String
    $BatchId: String
    $LotId: String
  ) {
    onDeleteAttachment(
      AttachmentId: $AttachmentId
      BatchId: $BatchId
      LotId: $LotId
    ) {
      AttachmentId
      Batch {
        BatchId
        Brand
        CreationDate
        IsPublic
        LotId
        Product
      }
      BatchId
      Brand
      DateAdded
      Incoming
      IsPublic
      LabType
      Lot {
        ArrivalDate
        IsPublic
        LotId
      }
      LotId
      Notes
      OriginUrl
      Title
      TotalCBD
      TotalTHC
      Url
    }
  }
`;
export const onDeleteBatch = /* GraphQL */ `
  subscription OnDeleteBatch($BatchId: String, $Brand: String, $LotId: String) {
    onDeleteBatch(BatchId: $BatchId, Brand: $Brand, LotId: $LotId) {
      Attachments {
        AttachmentId
        BatchId
        Brand
        DateAdded
        Incoming
        IsPublic
        LabType
        LotId
        Notes
        OriginUrl
        Title
        TotalCBD
        TotalTHC
        Url
      }
      BatchId
      Brand
      CreationDate
      IsPublic
      Lot {
        ArrivalDate
        IsPublic
        LotId
      }
      LotId
      Product
    }
  }
`;
export const onDeleteLots = /* GraphQL */ `
  subscription OnDeleteLots(
    $ArrivalDate: String
    $IsPublic: String
    $LotId: String
  ) {
    onDeleteLots(
      ArrivalDate: $ArrivalDate
      IsPublic: $IsPublic
      LotId: $LotId
    ) {
      ArrivalDate
      Attachments {
        AttachmentId
        BatchId
        Brand
        DateAdded
        Incoming
        IsPublic
        LabType
        LotId
        Notes
        OriginUrl
        Title
        TotalCBD
        TotalTHC
        Url
      }
      Batches {
        BatchId
        Brand
        CreationDate
        IsPublic
        LotId
        Product
      }
      IsPublic
      LotId
    }
  }
`;
export const onUpdateAttachment = /* GraphQL */ `
  subscription OnUpdateAttachment(
    $AttachmentId: String
    $BatchId: String
    $LotId: String
  ) {
    onUpdateAttachment(
      AttachmentId: $AttachmentId
      BatchId: $BatchId
      LotId: $LotId
    ) {
      AttachmentId
      Batch {
        BatchId
        Brand
        CreationDate
        IsPublic
        LotId
        Product
      }
      BatchId
      Brand
      DateAdded
      Incoming
      IsPublic
      LabType
      Lot {
        ArrivalDate
        IsPublic
        LotId
      }
      LotId
      Notes
      OriginUrl
      Title
      TotalCBD
      TotalTHC
      Url
    }
  }
`;
export const onUpdateBatch = /* GraphQL */ `
  subscription OnUpdateBatch($BatchId: String, $Brand: String, $LotId: String) {
    onUpdateBatch(BatchId: $BatchId, Brand: $Brand, LotId: $LotId) {
      Attachments {
        AttachmentId
        BatchId
        Brand
        DateAdded
        Incoming
        IsPublic
        LabType
        LotId
        Notes
        OriginUrl
        Title
        TotalCBD
        TotalTHC
        Url
      }
      BatchId
      Brand
      CreationDate
      IsPublic
      Lot {
        ArrivalDate
        IsPublic
        LotId
      }
      LotId
      Product
    }
  }
`;
export const onUpdateLots = /* GraphQL */ `
  subscription OnUpdateLots(
    $ArrivalDate: String
    $IsPublic: String
    $LotId: String
  ) {
    onUpdateLots(
      ArrivalDate: $ArrivalDate
      IsPublic: $IsPublic
      LotId: $LotId
    ) {
      ArrivalDate
      Attachments {
        AttachmentId
        BatchId
        Brand
        DateAdded
        Incoming
        IsPublic
        LabType
        LotId
        Notes
        OriginUrl
        Title
        TotalCBD
        TotalTHC
        Url
      }
      Batches {
        BatchId
        Brand
        CreationDate
        IsPublic
        LotId
        Product
      }
      IsPublic
      LotId
    }
  }
`;
