import { Component, OnInit, Input, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { Attachment } from '../classes/attachment';
import { Batch } from '../classes/batch';
import { RawLot } from '../classes/raw-lot';
import { AttachmentModalComponent } from '../attachment-modal/attachment-modal.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AttachmentService } from '../services/attachment.service';
import { BaseGraphqlServiceService } from '../services/base-graphql-service.service';

@Component({
  selector: 'app-inline-attachments',
  templateUrl: './inline-attachments.component.html',
  styleUrls: ['./inline-attachments.component.scss']
})
export class InlineAttachmentsComponent implements OnInit {

  @Input()
  attachments: Array<Attachment>;

  @Output()
  attachmentsChange = new EventEmitter<Array<Attachment>>();

  @Input()
  BatchId: string;

  @Input()
  LotId: string;

  @Input()
  batch: Batch;
  @Input()
  lot: RawLot;

  @Input()
  disabled = false;

  constructor(
    private dialog: MatDialog,
    private changeDetection: ChangeDetectorRef,
    private attachmentService: AttachmentService,
    private graphQlService: BaseGraphqlServiceService
    ) { }

  ngOnInit() {
    if ( this.BatchId ) {
      this.graphQlService.getBatch( this.BatchId )
      .subscribe( batch => { this.attachments = batch.Attachments })
    }
    if ( this.batch ) {
      this.attachmentService.getAllForBatch( this.batch.LotId, this.batch.BatchId)
      .subscribe( atts => {
        this.attachments = atts;
      });
    }
    if ( this.lot ) {
      this.attachmentService.getAllForLot( this.lot.LotId)
      .subscribe( atts => {
        this.attachments = atts;
      });
    }

  }

  openAttachmentDialog(  ) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    if ( this.batch) {
      dialogConfig.data = {
        batchId : this.batch.BatchId,
        lotId : this.batch.LotId
      };
    }
    if ( this.lot) {
      dialogConfig.data = {
        lotId : this.lot.LotId
      };
    }

    const dialogRef = this.dialog.open(AttachmentModalComponent, dialogConfig);
    dialogRef.afterClosed()
    .subscribe(attachment => {
      if( attachment ){
        this.attachments.push(attachment);
        this.changeDetection.detectChanges();
        this.attachmentsChange.emit(this.attachments);
      }
    })
  }

  addAttachment() {
    this.openAttachmentDialog();
  }
}
