import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GetRowIdParams, GridOptions, RowSelectedEvent } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { BatchGridCellAttachmentComponent } from '../batch-grid-cell-attachment/batch-grid-cell-attachment.component';
import { BatchComponent } from '../batch/batch.component';
import { Attachment } from '../classes/attachment';
import { Batch } from '../classes/batch';
import { BaseGraphqlServiceService } from '../services/base-graphql-service.service';

@Component({
  selector: 'app-batch-grid-editor',
  templateUrl: './batch-grid-editor.component.html',
  styleUrls: ['./batch-grid-editor.component.scss']
})
export class BatchGridEditorComponent implements OnInit {

  gridOptions: GridOptions;

  BatchFetch: Observable<Array<Batch>>;
  Batches: Array<Batch>;
  selectedBatch:Batch = null;
  defaultBatchProperties: any = {LotId:'NO-LOT',Brand:'GW'};

  constructor(
    private dialog: MatDialog,
    private graphQLService: BaseGraphqlServiceService) {
      this.BatchFetch = this.graphQLService.getBatches();
      this.graphQLService.BatchCreated.subscribe( this.onBatchModified )
      this.graphQLService.BatchModified.subscribe( this.onBatchModified )
      this.graphQLService.BatchDeleted.subscribe( this.onBatchModified )
      this.initGridOptions();
      this.BatchFetch.subscribe( batches => {
        this.Batches = batches;
        if(this.gridOptions && this.gridOptions.api ){
          this.gridOptions.api.setRowData(this.Batches);
        }
      });
      }

  ngOnInit(): void {
  }

  onBatchModified( batch: Batch ){
    // May be modified and may be created
    this.gridOptions.api.addItems(new Array(batch));

  }

  onAttachmentMofified( attachment:Attachment){

  }

  getRowNodeId(params: GetRowIdParams): string{
    return params.data.BatchId;
  }

  initGridOptions(){
    const columnDefs = [
      {
        headerName: 'Batch ID',
        field: 'BatchId'
      },
      {
        headerName: 'Creation Date',
        field: 'CreationDate',
        valueFormatter: (colData) => {return colData.value.toLocaleString()},
        filter: 'agDateColumnFilter',
        sort:'desc'
      },
      {
        headerName: 'Product',
        field: 'Product'
      },
      {
        headerName: 'Attachments',
        field: 'Attachments',
        width: 60,
        resizable: true,
        filter:false,
        cellRenderer: BatchGridCellAttachmentComponent
      },
      {
        headerName:'Created By',
        hide:true
      },
      ,
      {
        headerName:'Modified By',
        hide:true
      }
    ];
    this.gridOptions = {
      onGridReady: ($event) => {
        if(this.Batches){
          $event.api.setRowData(this.Batches)
        }
      },
      rowHeight: 40,
      columnDefs: columnDefs,
      defaultColDef: {
        resizable: true,
        flex: 1,
        minWidth: 150,
        filter: true,
        sortable: true
      }
    } as GridOptions;
  }

  onCellDoubleClicked($event: any){
    this.openBatchDialog($event.data);
  }

  doAction(actionName: string){
    alert(actionName);
  }

  addBatch() {
    const newBatch = new Batch(this.defaultBatchProperties);
    this.openBatchDialog(newBatch);

  }

  editBatch() {
    this.openBatchDialog(this.selectedBatch);
  }

  deleteBatch(){
    alert('Not Implemented');

  }

  onRowSelected($event: RowSelectedEvent){
    this.selectedBatch = $event.data;
  }

  openBatchDialog( editBatch: Batch ) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      batch: editBatch
    };

    const dialogRef= this.dialog.open(BatchComponent, dialogConfig);

    dialogRef.afterClosed()
    .subscribe( batch => {
      if ( batch !== null && batch !== undefined ) {

        this.updateOrAddBatch( batch );
      }
    });

  }

  updateOrAddBatch( batch: Batch ) {
    // Test to see if this batch is already in the grid
    var batchFound = false;
    this.gridOptions.api.forEachNode( row => {
      if( row.data.BatchId === batch.BatchId){
        batchFound = true;
      }
    });

    // Add to grid if not found
    var trans = {};
    if(batchFound){
      trans = {update:new Array(batch)};
    } else {
      trans = {addIndex:0,add:new Array(batch)};
    }
    this.gridOptions.api.applyTransaction(
      trans
    );
}


}
