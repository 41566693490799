import { Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { BrandMeta } from '../classes/brand-meta';
import { BrandService } from '../services/brand.service';

@Component({
  selector: 'app-brand-list',
  templateUrl: './brand-list.component.html',
  styleUrls: ['./brand-list.component.scss']
})
export class BrandListComponent implements OnInit {

  public brands: Array<BrandMeta> = [];
  displayedColumns: string[] = ['name'];
  clickedRow:BrandMeta = null;



  constructor( private brandService: BrandService ) {
    this.brandService.Added$.subscribe(   this.onNewBrand );
    this.brandService.Changed$.subscribe( this.onEditBrand );
    this.brandService.Deleted$.subscribe( this.onDeleteBrand );
    this.brandService.getAll().subscribe( brands => {
      this.brands = brands;
    })
  }

  rowClicked(row:BrandMeta){
    this.clickedRow=row;
  }

  ngOnInit(): void {
  }

  onNewBrand( brand: BrandMeta ){

  }
  onEditBrand( brand:BrandMeta ){

  }
  onDeleteBrand( brand:BrandMeta ){

  }

  addBrand(){

  }

  onCellDoubleClicked(evt: Event){

  }

}
