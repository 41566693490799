import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RawLotComponent } from './raw-lot/raw-lot.component';
import { BatchComponent } from './batch/batch.component';
import { AttachmentComponent } from './attachment/attachment.component';
import { HttpClientModule } from '@angular/common/http';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { BatchService } from './services/batch.service';
import { AttachmentService } from './services/attachment.service';
import { RawLotService } from './services/raw-lot.service';
import { UserService } from './services/user.service';
import { DataService } from './services/data.service';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import {MatCardModule} from '@angular/material/card';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSortModule} from '@angular/material/sort';



import { NgxFileDropModule } from 'ngx-file-drop';
import {AgGridModule} from 'ag-grid-angular';
import {MatMenuModule} from '@angular/material/menu';
import { MatTabsModule} from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTableModule } from '@angular/material/table';
import { MatRadioModule } from '@angular/material/radio';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer'; // <-- Import PdfJsViewerModule module
import { GoogleTagManagerModule } from 'angular-google-tag-manager';


import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BaseDialogComponent } from './base-dialog/base-dialog.component';
import { BatchListComponent } from './batch-list/batch-list.component';
import { LotListComponent } from './lot-list/lot-list.component';
import { CustomerViewComponent } from './customer-view/customer-view.component';
import { AttachmentModalComponent } from './attachment-modal/attachment-modal.component';
import { InlineAttachmentComponent } from './inline-attachment/inline-attachment.component';
import { InlineAttachmentsComponent } from './inline-attachments/inline-attachments.component';
import { SortByPipe } from './pipes/sort-by.pipe';
import { NgxUpperCaseDirectiveModule } from 'ngx-upper-case-directive';
import { QwertyMainComponent } from './qwerty-main/qwerty-main.component';
import { environment, AuthConfig } from 'src/environments/environment';
import { LotUnknownComponent } from './lot-unknown/lot-unknown.component';
import { LabNavTabsComponent } from './lab-nav-tabs/lab-nav-tabs.component';
import { SafePipe } from './pipes/safe.pipe';
import { ExceptPipe } from './pipes/except.pipe';
import { AttachmentViewEditComponent } from './attachment-view-edit/attachment-view-edit.component';
import { BrandSelectComponent } from './brand-select/brand-select.component';
import { Amplify } from "aws-amplify";
import { BrandService } from './services/brand.service';
import { BrandEditComponent } from './brand-edit/brand-edit.component';
import { BrandListComponent } from './brand-list/brand-list.component';
import { InGroupDirective } from './directives/in-group.directive';
import { HelloComponent } from './hello/hello.component';
import { AuthGuardService } from './services/auth-guard.service';
import { BatchGridEditorComponent } from './batch-grid-editor/batch-grid-editor.component';
import { EcoFabSpeedDialModule } from '@ecodev/fab-speed-dial';
import { BatchGridCellAttachmentComponent } from './batch-grid-cell-attachment/batch-grid-cell-attachment.component';

Amplify.configure( AuthConfig );

@NgModule({
  declarations: [
    AppComponent,
    RawLotComponent,
    BatchComponent,
    AttachmentComponent,
    HeaderComponent,
    FooterComponent,
    BaseDialogComponent,
    BatchListComponent,
    LotListComponent,
    CustomerViewComponent,
    AttachmentModalComponent,
    InlineAttachmentComponent,
    InlineAttachmentsComponent,
    SortByPipe,
    QwertyMainComponent,
    LotUnknownComponent,
    LabNavTabsComponent,
    SafePipe,
    ExceptPipe,
    AttachmentViewEditComponent,
    BrandSelectComponent,
    BrandEditComponent,
    BrandListComponent,
    InGroupDirective,
    HelloComponent,
    BatchGridEditorComponent,
    BatchGridCellAttachmentComponent
  ],
  imports: [
    MatProgressSpinnerModule,
    MatIconModule,
    MatToolbarModule,
    MatDialogModule,
    MatFormFieldModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatInputModule,
    MatTabsModule,
    MatBadgeModule,
    MatCheckboxModule,
    MatSelectModule,
    MatProgressBarModule,
    MatDividerModule,
    MatTableModule,
    MatCardModule,
    MatExpansionModule,
    MatSortModule,
    MatRadioModule,
    MatPaginatorModule,
    NgbModule,
    NgxUpperCaseDirectiveModule,
    PdfJsViewerModule,
    AgGridModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AmplifyAuthenticatorModule,
    NgxFileDropModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatTabsModule,
    EcoFabSpeedDialModule,
    GoogleTagManagerModule.forRoot({
      id: environment.gtmID,
    })
  ],
  providers: [
    BatchService,
    BrandService,
    AttachmentService,
    MatNativeDateModule,
    RawLotService,
    UserService,
    DataService,
    AuthGuardService
    //,    {provide: 'googleTagManagerId',  useValue: environment.gtmID}
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    RawLotComponent,
    BatchComponent,
    AttachmentModalComponent,
    BaseDialogComponent
  ]
})
export class AppModule { }
