import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '../../environments/environment';
import { MatSelectChange } from '@angular/material/select';
import { BrandService } from '../services/brand.service';
import { BrandMeta } from '../classes/brand-meta';

@Component({
  selector: 'app-brand-select',
  templateUrl: './brand-select.component.html',
  styleUrls: ['./brand-select.component.scss'],
})
export class BrandSelectComponent implements OnInit {

  @Output()
  brandChange = new EventEmitter();

  @Required()
  @Input()
  brand: string;

  brands: Array<BrandMeta>;

  env = environment;

  constructor(private brandService: BrandService) { }

  ngOnInit() {
    this.brandService.getAll().subscribe( brands => {
      this.brands = brands;
    })

  }


  selectionChanged( event$: MatSelectChange ) {
    this.brandChange.emit(this.brand);
  }

  notifySettings() {
  }

}

// https://dev.to/gaetanrdn/how-to-handle-required-inputs-in-angular-584o
export function Required() {
  return function (target: any, key: string): void {
      const NG_ON_INIT = 'ngOnInit';

      // eslint-disable-next-line @typescript-eslint/ban-types
      const original: Function | null = target[NG_ON_INIT];

      target[NG_ON_INIT] = function () {

          if (this[key] === undefined) {
              throw new Error(`Property ${key} is required`);
          }

          if (original) {
              original.apply(this);
          }
      };
  };
}
