import { RawLot } from './raw-lot';
import { Product } from './product';
import { Attachment } from './attachment';

export class Batch {
  LotId: string;
  BatchId: string;
  Brand: string;
  Product: string;
  CreationDate: Date;
  IsPublic: boolean;
  Notes: string;
  Attachments: Array<Attachment>;

  constructor(defaults: {} = null) {
    this.LotId = '';
    this.BatchId = '';
    this.Brand = '';
    this.Product = '';
    this.CreationDate = new Date();
    this.IsPublic = false;
    this.Notes = '';
    this.Attachments = new Array<Attachment>();
    if ( defaults ) {
      for ( const key in defaults) {
        if( key === "CreationDate" ){
          this.CreationDate = new Date(defaults[key]);
        } else if( key === "IsPublic" ){
          this.IsPublic = defaults[key] == 'true';
        }else if( key === "Attachments" ){
            defaults[key].forEach( attachment => this.Attachments.push(new Attachment(attachment)) );
        } else if ( this.hasOwnProperty(key) ) {
          this[key] = defaults[key];
        }
      }
    }
  }

  public getNextAttachment( attachment: Attachment ) {
    if ( this.Attachments ) {
      let attachmentIndex =  this.Attachments.indexOf(attachment);
      if ( attachmentIndex && this.Attachments.length >= attachmentIndex ) {
        return this.Attachments[++attachmentIndex];
      }
    } else {
      return null;
    }
  }

  public getPrevAttachment( attachment: Attachment ) {
    if ( this.Attachments ) {
      let attachmentIndex =  this.Attachments.indexOf(attachment);
      if ( attachmentIndex && attachmentIndex > 0 ) {
        return this.Attachments[--attachmentIndex];
      }
    } else {
      return null;
    }
  }

}
