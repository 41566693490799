import { Component, OnInit, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { RawLot } from '../classes/raw-lot';
import { Batch } from '../classes/batch';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserService } from '../services/user.service';
import { DataService } from '../services/data.service';
import { RawLotService } from '../services/raw-lot.service';
import { BatchService } from '../services/batch.service';
import { RawLotComponent } from '../raw-lot/raw-lot.component';
import { BatchComponent } from '../batch/batch.component';
import { AttachmentModalComponent } from '../attachment-modal/attachment-modal.component';
import * as KeyCode from 'keycode-js';

@Component({
  selector: 'app-qwerty-main',
  templateUrl: './qwerty-main.component.html',
  styleUrls: ['./qwerty-main.component.scss']
})
export class QwertyMainComponent implements OnInit {

  title = 'Qwerty Portal';
  loginDialogRef: any;
  rawLots: Observable<Array<RawLot>>;
  batches: Observable<Array<Batch>>;

  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    private data: DataService,
    private rawLotService: RawLotService,
    private batchService: BatchService) {

    }

  ngOnInit(): void {
    this.userService.CurrentUser$.subscribe( user => {
      // Perform some action when user is authenticated
    });
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {

    // https://www.npmjs.com/package/keycode-js
    // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
    switch ( event.keyCode ) {
      case(KeyCode.KEY_LEFT):
      case(KeyCode.KEY_RIGHT):
      case(KeyCode.KEY_DOWN):
      case(KeyCode.KEY_UP):
      case(KeyCode.KEY_F1):
      case(KeyCode.KEY_F2):
      case(KeyCode.KEY_F3):
      case(KeyCode.KEY_F4):
      case(KeyCode.KEY_F5):
      case(KeyCode.KEY_F6):
      case(KeyCode.KEY_F7):
      case(KeyCode.KEY_F8):
      case(KeyCode.KEY_F9):
      case(KeyCode.KEY_F10):
      case(KeyCode.KEY_PAGE_DOWN):
      case(KeyCode.KEY_PAGE_UP):
        this.data.newKeyboardNavEvent(event);
        break;
      default:
        break;
    }
  }

  openRawLotDialog( editLot: RawLot ) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      lot: editLot
    };

    this.dialog.open(RawLotComponent, dialogConfig);
  }

  openBatchDialog( editBatch: Batch ) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      batch: editBatch
    };

    this.dialog.open(BatchComponent, dialogConfig);
  }

  openAttachmentDialog(  ) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
    };

    this.dialog.open(AttachmentModalComponent, dialogConfig);
  }

  fabClick( event: any ) {
    switch ( event ) {
      case( 'Raw Lot' ):
        this.openRawLotDialog(null);
        break;
      case( 'Batch' ):
        this.openBatchDialog(null);
        break;
      case( 'Attach' ):
        this.openAttachmentDialog();
        break;
      default:
        break;
    }
  }

  close() {
    this.loginDialogRef.close();
  }

}
