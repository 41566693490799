<div class="inline-attachments">
  <div class="inline-attachments-list">
    <ol *ngIf="attachments" class="attachment-list">
    <div *ngIf="attachments?.length == 0">
      There are no attachments.
    </div>
    <app-inline-attachment *ngFor="let attachment of attachments" [attachment]="attachment"></app-inline-attachment>
    <!-- TODO: Add mat-table -->
    </ol>
  </div>
  <div class="inline-attachments-add-button">
      <button [disabled]="disabled" mat-stroked-button (click)="addAttachment()"><mat-icon>attachment</mat-icon> Add</button>
  </div>
</div>
