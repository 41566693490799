import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrandListComponent } from './brand-list/brand-list.component';
import { HelloComponent } from './hello/hello.component';
import { QwertyMainComponent } from './qwerty-main/qwerty-main.component';
import { AuthGuardService } from './services/auth-guard.service';


const appRoutes: Routes = [
  { path: 'hello',
    component: HelloComponent
  },
  { path: 'qwerty',
    component: QwertyMainComponent,
  },
  {
    path: 'brand',
    component: BrandListComponent
  },
  { path: '**',
    redirectTo: 'qwerty',
    pathMatch: 'full'
  },
  // ,{ path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
