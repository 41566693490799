import { Component, Input, OnInit } from '@angular/core';
import { UserService } from './services/user.service';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { User } from './classes/user';
import { DataService } from './services/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Stasis Portal';

  @Input()
  CurrentUser: User = null;

  @Input()
  Authenticated: boolean = false;

  Busy: boolean;

  constructor(
      private router: Router,
      private data: DataService,
      private gtmService: GoogleTagManagerService,
      public userService: UserService) {
  }

  ngOnInit(): void {
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
    this.userService.CurrentUser$.subscribe( user => {
      this.CurrentUser = user;
      this.Authenticated = (user !== null);
    });
    this.data.Busy.subscribe( busy => {
      console.log(`Busy: ${busy}`);
      this.Busy = busy;
    });
    this.userService.checkExistingLogin();
  }

  signIn() {
    this.userService.federatedLogin();
  }

  signOut() {
    this.userService.signOut();
  }
}
