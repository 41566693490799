import { Component, OnInit, Inject } from '@angular/core';
import { RawLotService } from '../services/raw-lot.service';
import { RawLot } from '../classes/raw-lot';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {ErrorStateMatcher} from '@angular/material/core';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { Attachment } from '../classes/attachment';
import { AttachmentService } from '../services/attachment.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormGroupDirective, NgForm } from '@angular/forms';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-raw-lot',
  templateUrl: './raw-lot.component.html',
  styleUrls: ['./raw-lot.component.scss']
})
export class RawLotComponent extends BaseDialogComponent implements OnInit, ErrorStateMatcher {


  Lot: RawLot;
  Attachments: Array<Attachment>;

  isolateIdPattern = environment.permittedIsolateNamePattern;
  lotNameControl: FormControl;
  matcher = this;
  form: FormGroup;

  constructor(
    private rawLotService: RawLotService,
    private attachmentService: AttachmentService,
    public dialogRef: MatDialogRef<RawLotComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      super(dialogRef);
      this.Lot = data.lot;
      this.attachmentService.Added$.subscribe( attachment => this.newAttachmentDetected(attachment) )
  }

  setupForm() {
    this.lotNameControl = new FormControl(this.Lot.LotId,[Validators.required, Validators.pattern(this.isolateIdPattern)]);

    this.form = new FormGroup({
      lotNameControl: this.lotNameControl
    });

  }

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }

  save() {
    if(this.form.invalid){
      alert('Please correct errors on form.');
    } else {
      this.rawLotService.create(this.Lot)
      .subscribe( lot => super.close( lot ) );
    }
  }

  close() {
    super.close(null);
  }

  newAttachmentDetected(attachment: Attachment) {
    if( ( (attachment.BatchId == "") || (attachment.BatchId == null) ) && (attachment.LotId === this.Lot.LotId) ) {
      // This is an attachment for this Lot
      this.Attachments.push(attachment);
    }
  }


  ngOnInit() {
    if ( this.Lot == null ) {
      this.Lot = new RawLot();
    }
    this.attachmentService.getAllForLot( this.Lot.LotId)
    .subscribe( atts => {
      this.Attachments = atts;
    });
    this.setupForm();
  }

}
